// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { VendorRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "hostname": "demo-staging.cdev.orangelv.com",
    "environment": "staging",
    "sportId": "various",
    "vendorId": "demo",
    "vendorName": "Demo customizer",
    "logoAsset": "olvDemo_logo.svg",
    "homeUrl": "https://olv.global/#spectacle",
    "orderEmail": "customizers@olv.global",
    "bakeryEmailTo": [
      "customizers@olv.global"
    ],
    "features": {
      "shareDesign": true,
      "factoryOutput": true,
      "unrealRender": true,
      "autoRotation": true,
      "fabric": true,
      "design": true,
      "productColor": true,
      "colorTone": true,
      "designColor": true,
      "teamName": true,
      "playerName": true,
      "playerNumber": true,
      "outline": true,
      "brandLogo": false,
      "teamLogo": true,
      "fonts": {
        "google": "all"
      },
      "fill": true,
      "spectacleLogo": true,
      "fullCustom": true,
      "skyboxes": [
        "Stadium",
        "StadiumNight",
        "Lockers",
        "Beach"
      ],
      "textSize": true
    },
    "defaults": {
      "designColor1Id": "lightOrange",
      "designColor2Id": "red",
      "designColor3Id": "yellow",
      "teamName": {
        "text": "Team OLV",
        "font": "google:Maven Pro/600",
        "colorId": "black",
        "outline1ColorId": "white",
        "outline2ColorId": "black"
      },
      "playerName": {
        "text": "Michael",
        "font": "google:Norican/regular",
        "colorId": "black"
      },
      "playerNumber": {
        "text": "1",
        "font": "google:Graduate/regular",
        "colorId": "black",
        "outline1ColorId": "white",
        "outline2ColorId": "black"
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<VendorRow[]>> = data;

export type Vendor = typeof typed[number];

export const VENDOR_INDEX_KEY = "hostname";
export type VendorIndexKey = "hostname";
export type VendorHostname = Vendor["hostname"];

let i = 0;
export const VENDOR_DICT = {
  "demo-staging.cdev.orangelv.com": typed[i++]
} as const;

export { typed as VENDORS };
